import { render, staticRenderFns } from "./Purchasing.vue?vue&type=template&id=7529beec&scoped=true&"
import script from "./Purchasing.vue?vue&type=script&lang=js&"
export * from "./Purchasing.vue?vue&type=script&lang=js&"
import style0 from "./Purchasing.vue?vue&type=style&index=0&id=7529beec&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7529beec",
  null
  
)

export default component.exports